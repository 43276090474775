import {cloneElement, createRef, lazy, Suspense} from 'react';
import DatePicker from 'react-datepicker';

import Field from 'Component/Field';
import Html from 'Component/Html';
import media, { WYSIWYG_MEDIA } from 'Util/Media/Media';

import '../style/GiftCardEmailPreviewProcessor.style.scss';
import 'react-datepicker/dist/react-datepicker.css';
export const GIFTPRODUCT = 'amgiftcard';
export const GIFTPRODUCTMEDIA = '/amasty/amgcard/image/admin_upload/';
import Loader from 'Component/Loader/Loader.component';
export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
    ));
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

export class ProductActionComponent {
    customPriceInput = createRef();

    fieldMap = {
        am_giftcard_recipient_name: {
            type: 'text',
            name: 'am_giftcard_recipient_name',
            id: 'am_giftcard_recipient_name',
            label: __('To')
        },
        am_giftcard_sender_name: {
            type: 'text',
            name: 'am_giftcard_sender_name',
            id: 'am_giftcard_sender_name',
            label: __('From (Name)')
        },
        am_giftcard_message: {
            type: 'textarea',
            name: 'am_giftcard_message',
            id: 'am_giftcard_message',
            label: __('Message (optional)')
        },
        am_giftcard_recipient_email: {
            type: 'text',
            name: 'am_giftcard_recipient_email',
            id: 'am_giftcard_recipient_email',
            label: __('Recipient Email')
        }
    };

    renderGiftPrice(giftPrice, instance) {
        const { value, price_id } = giftPrice;
        const { base_currency_code, giftOptions: { am_giftcard_amount } } = instance.props;
        let optionSelectedClass = '';

        if (am_giftcard_amount == value) {
            optionSelectedClass = 'selected';
        }

        return (
            <label htmlFor="gift_price" className={ "Field-Value "+optionSelectedClass }>
                    <Field
                      name="gift_price"
                      id={ `gift_price_${price_id}` }
                      value={ value }
                      onClick={ () => this.addPrice(value, instance) }
                      checked={ am_giftcard_amount == value }
                      type="radio"
                    />
                    { `${base_currency_code} ${value}` }
            </label>
        );
    }

    renderGiftOptions(instance) {
        const {
            am_display_option_fields,
            product: { type_id, am_giftcard_images },
            giftOptions: { is_date_delivery, am_giftcard_date_delivery },
            am_time_zones
        } = instance.props;

        if (type_id !== GIFTPRODUCT) {
            return null;
        }

        return (
            <div
              mix={ { block: 'ProductActions', elem: 'GiftOptions' } }
            >
                <div className="ProductActions-GiftOptions_Fields">
                    { this.renderGiftCardField('am_giftcard_recipient_email', instance) }
                    { am_display_option_fields.map((code) => this.renderGiftCardField(code, instance)) }
                </div>
                {/*<div className="giftcard-preview-container">*/}
                {/*    <input*/}
                {/*      type="button"*/}
                {/*      className="giftcard-preview-button"*/}
                {/*      onClick={ () => instance.props.buttonClick(true) }*/}
                {/*      value="Preview Gift Card"*/}
                {/*    />*/}
                {/*</div>*/}
                { this.renderGiftCardOverlay(instance) }
                { this.renderCardValue(instance) }
                {/*<div className="Field">*/}
                {/*    <label htmlFor="is_date_delivery" className="Field-Label">{ __('Schedule delivery') }</label>*/}
                    {/*<div className="date_delivery_options">*/}
                    {/*    <div className="Field">*/}
                    {/*        <label>{ __('Send now') }</label>*/}
                    {/*        <input*/}
                    {/*          onClick={ () => instance.props.setGiftOptions({ is_date_delivery: '0' }) }*/}
                    {/*          checked={ !is_date_delivery || is_date_delivery === '0' }*/}
                    {/*          id="is_date_delivery"*/}
                    {/*          name="is_date_delivery"*/}
                    {/*          value="0"*/}
                    {/*          type="radio"*/}
                    {/*        />*/}
                    {/*        <label />*/}
                    {/*    </div>*/}
                    {/*    <div className="Field">*/}
                    {/*        <label>{ __('Send later') }</label>*/}
                    {/*        <input*/}
                    {/*          onClick={ () => instance.props.setGiftOptions({ is_date_delivery: '1' }) }*/}
                    {/*          id="is_date_delivery"*/}
                    {/*          name="is_date_delivery"*/}
                    {/*          value="1"*/}
                    {/*          type="radio"*/}
                    {/*          checked={ is_date_delivery === '1' }*/}
                    {/*        />*/}
                    {/*        <label />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*{ is_date_delivery === '1'*/}
                {/*    && (*/}
                {/*    <div>*/}
                {/*        <div className="Field">*/}
                {/*            <label htmlFor="am_giftcard_date_delivery" className="Field-Label">{ __('Delivery Date') }</label>*/}
                {/*            <DatePicker*/}
                {/*              selected={ am_giftcard_date_delivery }*/}
                {/*              name="am_giftcard_date_delivery"*/}
                {/*              id="am_giftcard_date_delivery"*/}
                {/*              onChange={ (value) => instance.props.setGiftOptions({ am_giftcard_date_delivery: value }) }*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*          <div className="Field">*/}
                {/*             <label htmlFor="am_giftcard_date_delivery" className="Field-Label">{__("Timezone")}</label>*/}
                {/*             <Field*/}
                {/*                 name="am_giftcard_date_delivery_timezone"*/}
                {/*                 id="am_giftcard_date_delivery_timezone"*/}
                {/*                 type="select"*/}
                {/*                 selectOptions={am_time_zones}*/}
                {/*                 onChange={(value)=> instance.props.setGiftOptions({ am_giftcard_date_delivery_timezone : value})}*/}
                {/*             />*/}
                {/*          </div>*/}
                {/*    </div>*/}
                {/*    ) }*/}
            </div>
        );
    }

    renderGiftCardOverlay(instance) {
        const { showGiftCardPreviewOverlay, htmlContent } = instance.props;
        const imgSrc = '';
        if (showGiftCardPreviewOverlay === true) {
            return (
                <div className="overlay showGiftCardPreviewOverlay-container">
                        <div className="showGiftCardPreviewOverlay-bkg" onClick={ () => instance.props.hideGiftCardPreviewProcessor(false) } />
                        <div className="popup showGiftCardPreviewOverlay-popup">
                            <a
                              className="close"
                              onClick={ () => instance.props.hideGiftCardPreviewProcessor(false) }
                            >
                                <UniversalIcon src={ media('admin_icons/close.png', WYSIWYG_MEDIA) } alt="X" />
                            </a>
                            <div>
                                <Html content={ htmlContent || '' } />
                            </div>
                        </div>
                </div>
            );
        }
    }

    renderGiftCardField(code, instance) {
        const { setGiftOptions } = instance.props;
        const fieldProps = this.fieldMap[code];
        if (!fieldProps) {
            return null;
        }
        if (fieldProps.type === 'text' || fieldProps.type === 'textarea') {
            const obj = {};
            fieldProps.onChange = (value) => {
                obj[code] = value;
                setGiftOptions(obj);
            };
        }

        return (
            <>
                { /* <label for={code}>{fieldProps.label}</label> */ }
                <Field { ...fieldProps } />
            </>
        );
    }

    renderCardValue(instance) {
        const {
            product: {
                am_giftcard_prices,
                am_allow_open_amount
            },
            base_currency_code,
            minMaxCustomPriceString,
            giftOptions: { am_giftcard_amount_custom, am_giftcard_amount }
        } = instance.props;

        return (
            <>
                <div className="ProductActions-GiftOptions_amount">
                    <label className="am_giftcard_label Field-Label">{__('Card Value in ') + base_currency_code}</label>
                    <div className="Field-Values">
                        {am_giftcard_prices && am_giftcard_prices.map((item) => this.renderGiftPrice(item, instance))}
                    </div>
                </div>
                {am_allow_open_amount === '1' ? (
                    <div onClick={() => this.customPriceInput.current.focus()} className="Field">
                        <div className="LabelWrapper">
                            <div className={`radio ${!am_giftcard_amount ? 'selected' : ""}`}></div>
                            <label htmlFor="am_giftcard_amount_custom" className="Field-Label">{__('Other amount:')}</label>
                        </div>
                        <input
                            type="text"
                            id="am_giftcard_amount_custom"
                            name="am_giftcard_amount_custom"
                            onChange={() => this.addCustomPrice(instance)}
                            onFocus={() => { this.customPriceInput.current?.value ? this.addCustomPrice(instance) : null }}
                            placeholder={minMaxCustomPriceString}
                            ref={this.customPriceInput}
                        />
                        {/* <button onClick={() => this.addCustomPrice(instance)} className="am_giftcard_amount_custom_add">{__('Add')}</button> */}
                        {
                            am_giftcard_amount_custom && <span className="CostDetails">{__('Custom Card Value: ') + `${base_currency_code} ${parseFloat(am_giftcard_amount_custom).toFixed(2)}`}</span>
                        }
                    </div>
                )
                    : null}
            </>
        );
    }

    addCustomPrice(instance) {
        const { value } = this.customPriceInput.current;
        this.addPrice(value, instance,true);
    }

    addPrice(value, instance,isCustom = false) {
        const { setGiftOptions } = instance.props;
        if (!isNaN(parseInt(value))) {
            if (isCustom) {
                setGiftOptions({ am_giftcard_amount: null, am_giftcard_amount_custom: value });
            } else {
                setGiftOptions({ am_giftcard_amount: value, am_giftcard_amount_custom: null });
            }
        }
    }

    renderConfigurableAttributes = (args, callback, instance) => (
        <>
                { callback.apply(instance, args) }
                { this.renderGiftOptions(instance) }
        </>
    );

    renderAddToCart = (args, callback, instance) => {
        const result = callback.apply(instance, args);
        return result ? cloneElement(
            result,
            { giftOptions: instance.props.giftOptions }
        ) : result;
    };

    render = (args, callback, instance) => (
        <>
            {
                instance.props.product && instance.props.product.type_id === 'amgiftcard' ?
                    <Suspense fallback={ <Loader /> }>
                        <ProductGallery
                            setGiftOptions={instance.props.setGiftOptions}
                            giftCardProps={instance.props}
                            product={ instance.props.productOrVariant }
                            areDetailsLoaded={ instance.props.areDetailsLoaded }
                        />
                    </Suspense>
                    :
                    ''
            }
            { callback.apply(instance, args) }
        </>
    );
}


const { renderConfigurableAttributes, renderAddToCart, render } = new ProductActionComponent();


export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderConfigurableAttributes,
            renderAddToCart,
            render
        }
    }
};
