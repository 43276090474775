import PropTypes from 'prop-types';
import Field from 'Component/Field/Field.container';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { formatCurrency, roundPrice } from 'Util/Price';
import getStore from 'Util/Store';
import { CheckoutPayment as SourceCheckoutPayment } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

/** @namespace Component/CheckoutPayment/Component */
export class CheckoutPayment extends SourceCheckoutPayment {
    render() {
        const {
            isSelected,
            method: { title, code }
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                    block="CheckoutPayment"
                    mods={ { isSelected } }
                    elem="Button"
                    type="button"
                    onClick={ this.onClick }
                >
                    <Field
                        type="checkbox"
                        id={ `option-${ title }` }
                        name={ `option-${ title }` }
                        checked={ isSelected }
                        disabled
                    />
                    { title }
                    { code === "klarna_pay_later" ?
                        <div className="klarna_pay_later">
                            <img src={media('klarna.png',WYSIWYG_MEDIA)}/>
                        </div>
                        : ""}
                    {code === "boxnow" ?
                        <p className="boxnow">
                            {`${formatCurrency(getStore().getState().CartReducer?.cartTotals?.quote_currency_code)}${roundPrice(0.5)}`}
                        </p>
                        : ""}
                </button>
            </li>
        );
    }
}

export default CheckoutPayment;
